































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator"
import ProductListComponent from "@/components/ProductListComponent.vue"
import ImageComponent from "@/components/ImageComponent.vue"
import Product from "@/models/Product"
import ProductService from "@/services/ProductService"
import CartModule from "@/store/CartModule"
import CartTool from "@/services/tool/CartTool"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import QuantitySelectorComponent from "@/components/QuantitySelectorComponent.vue"
import Dialog from "@/models/vue/Dialog"
import DialogModule from "@/store/DialogModule"
import LoginService from "@/services/LoginService"

@Component
export default class CookiePoliciesView extends Vue {

  lang: any = getModule(LangModule).lang

  editorCookieHeaders = [
    {text: "Propiedad", value: "property", width: "20%", align: "center"},
    {text: "Cookie", value: "cookie", width: "15%", align: "center"},
    {text: "Finalidad", value: "purpose", width: "45%", align: "center"},
    {text: "Plazo", value: "period", width: "20%", align: "center"},
  ]

  editorCookieItems = [
    {
      property: "perfectbeauty.es",
      cookie: "__utma",
      purpose: "ID utiliza para identificar a los usuarios y sesiones",
      period: "En 2 años"
    },
    {
      property: "perfectbeauty.es",
      cookie: "__utmb",
      purpose: "Se utiliza para distinguir nuevas sesiones y visitas. Esta cookie se establece cuando se carga la biblioteca ga.js de JavaScript y no hay ninguna cookie __utmb existente. La cookie se actualiza cada vez que se envía los datos al servidor de Google Analytics.",
      period: "Sesión"
    },
    {
      property: "perfectbeauty.es",
      cookie: "__utmc",
      purpose: "Se utiliza sólo con versiones anteriores de Urchin de Google Analytics y no con ga.js. Se utilizó para distinguir entre las nuevas sesiones y visitas al final de una sesión.",
      period: "Sesión"
    },
    {
      property: "perfectbeauty.es",
      cookie: "__utmt",
      purpose: "Se utiliza para monitorizar el número de peticiones al servidor de Google Analytics.",
      period: "Sesión"
    },
    {
      property: "perfectbeauty.es",
      cookie: "__utmz",
      purpose: "Contiene información sobre la fuente de tráfico o campaña que dirige usuario al sitio web. La cookie se establece cuando los ga.js. Javascript se carga y se actualizan cuando se envían datos al servidor de Google Analytics",
      period: "En 7 meses"
    },

  ]

  externalProvidersHeaders = [
    {text: "Editor", value: "editor", width: "30%", align: "center"},
    {text: "Politica de Privacidad", value: "policy", width: "70%", align: "center"},
  ]

  externalProvidersItems = [
    { editor: "Google Analytics", policy: "https://privacy.google.com/take-control.html" }
  ]
}
